import classNames from 'classnames'
import { ArtworkCard, ArtworkCardProps } from 'components/artwork/ArtworkCard'
import { Carousel } from 'components/carousels/Carousel'
import { Skeletons } from 'components/common/Skeletons'
import { useCurrencies } from 'contexts/hooks/useCurrencies'
import useTranslation from 'next-translate/useTranslation'
import { fetchArtworksPrices, getArtworkPrices } from 'pages'
import { useQuery } from 'react-query'
import { PropsWithClassName } from 'types'

const queryConfig = { refetchOnMount: true, refetchOnWindowFocus: false }

interface TrendingArtworksCarouselProps extends PropsWithClassName {
  trendingArtworks?: ArtworkCardProps[] | null
}

/**
 * As of April 2024:
 *
 * Trending artworks should be updated once every hour.
 *
 * The data is fetched on the server `pagex/index.tsx/getServerSideProps` and cached for an hour.
 *
 * Anouther request here would be useless and would only impact performance, so don't do it.
 */
export const TrendingArtworksCarousel = ({
  trendingArtworks,
  className,
}: TrendingArtworksCarouselProps) => {
  const { t } = useTranslation()
  const { userCurrency, isLoadingUserCurrency } = useCurrencies()

  /**
   * Even though the artworks are being cached, we want to display the prices in real time.
   *
   * So we do a request to get the real-time prices and use these. The size of the response is aprox. 70% smaller.
   */
  const { data: prices } = useQuery(
    'featuredArtworksPrices',
    async () => await fetchArtworksPrices(trendingArtworks),
    queryConfig
  )

  return !trendingArtworks || trendingArtworks?.length ? (
    <Carousel
      viewAllHref='/artworks'
      viewAllLabel={t('common:view_all')}
      title={t('common:trending')}
      className={classNames('py-6 md:py-8', className)}
    >
      {trendingArtworks?.map((artwork, index) => {
        const { buyPrice, sellPrice } = getArtworkPrices(
          artwork,
          userCurrency,
          prices
        )

        return (
          <ArtworkCard
            key={index}
            {...artwork}
            highestBid={!isLoadingUserCurrency ? { price: sellPrice } : null}
            lowestAsk={!isLoadingUserCurrency ? { price: buyPrice } : null}
          />
        )
      })}
      {!trendingArtworks && <Skeletons type='artwork' />}
    </Carousel>
  ) : null
}
